.login-page{
    width: 100vw;
    height:100vh;
    min-width: 600px;
    min-height: 600px;
    background-color: white;
    position: fixed;
    z-index: 999;
    top:0;
    display: flex;
}
.login-page-left{
    width:50%;
    background-color: #3A53EA;
    color:white;
    display: flex;
    transform: skew(-10deg);
    transform-origin: top left;
}
.login-page-left h1{
    font-weight: 400;
    font-size: 50px;
    margin:auto;
    border-left: 3px solid white;
    padding:2rem;
}
.login-page-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-around;
    
}
.login-form{
    width: 500px;
}
.login-form button{
    width: 100%;
    height: 56px;
}
.login-page-right-links{
    text-align: center;
    color:#40BBF7;
    text-decoration: underline;
    line-height: 1.6rem;
}
