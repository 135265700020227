.home-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.home-container h2{
    font-size: 44px;
    color:#4389E9;
}
.home-container p{
    font-size: 24px;
    max-width: 700px;
    text-align: center;
}
.home-container a {
    color: #40BBF7;
}