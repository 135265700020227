.download-name-wrapper {
  border: 3px solid #6878f0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: white;
}
.download-name-wrapper:hover {
  cursor: pointer;
}
.download-name-wrapper.active,
.download-format-wrapper.active {
  background-image: linear-gradient(90deg, #8f32e9, #40bbf7);
  color: white;
  position: relative;
}
.download-format-wrapper {
  display: flex;
  align-items: center;
  border: 3px solid #6878f0;
  border-radius: 10px;
  padding: 3px;
  text-align: center;
  justify-content: center;
  background-color: white;
}
.download-format-button {
  margin-left: 40px !important;
  margin-top: 60px !important;
  background-image: linear-gradient(90deg, #7969e6, #40bbf7);
}
