.tool-bar-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px
}

.btn {
    margin-right: 10px !important;
}