.header{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin:auto;
    -webkit-box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.2);
    background-color: white;
    position: relative;
    z-index: 99;
}

.header-logo{
    display: flex;
}
.header-logo a{
    padding: 10px;
}
.header-logo img{
    height: 55px;
    width:auto;
}
.header h1{
    text-align: center;
}

.header-login{
    display: flex;
    justify-self: end;
    background-color: #ececec;
}
.header-login button{
    margin:auto;
    font-size: 25px;
}
 