.navbar{
    height: calc(100vh - 81px);
    background-color: white;
   
}
.navbar ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
.navbar ul li{
    /* background-color: red; */
    height: 50px;
}
.navbar a {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
}
.navbar a.active,
.navbar a:hover { 
    color: #4389E9;
    background-color: #ECECEC;
    font-weight: bold;
    /* height: 50px; */
}
.navbar a.active{
    position: relative;
}
.navbar a.active:after{
    content: "";
    position:absolute;
    top:0;
    height:100%;
    width: 5px;
    right:-15px;
    border-right:15px solid #ECECEC;
}

.d-tag{
    background-color: #ECECEC;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.m-r{
    margin-right: 10px;
}


