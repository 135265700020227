.licenses-card {
  background-color: white;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 250px;
  border-radius: 10px;
  border: 2px solid #6878f0;
}
.licenses-card p {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 10px;
  color: #4389e9;
  font-weight: bold;
  font-size: 18px;
}
