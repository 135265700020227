.certificate-wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.certificate-wrapper .page {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr 3fr 2fr;
  height: 792px;
  width: 100%;
}
.certificate-wrapper .page > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.certificate-wrapper .first-page {
  background-image: url("/images/certificate_bg1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.certificate-wrapper .first-page .title {
  grid-column: 1 / 3;
  color: white;
  align-items: flex-start;
  padding-left: 70px;
}
.certificate-wrapper .first-page .content {
  grid-column: 1 / 3;
  grid-row: 2/3;
  background-color: white;
  align-items: flex-start;
  padding-left: 70px;
  padding-right: 70px;
}
.certificate-wrapper .first-page .logo {
  grid-column: 3/ 4;
  grid-row: 2/3;
  background-color: white;
}
.certificate-wrapper .first-page .verification-code {
  grid-column: 1 / 2;
  grid-row: 3/4;
  justify-content: start;
  align-items: flex-start;
  padding-left: 70px;
}
.certificate-wrapper .first-sign {
  grid-column: 2 / 3;
  grid-row: 3/4;
}
.certificate-wrapper .second-sign {
  grid-column: 3/ 4;
  grid-row: 3/4;
}
.certificate-wrapper .first-page .title h3 {
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0;
  text-align: left;
}
.certificate-wrapper .first-page .title p {
  font-size: 1.2rem;
  font-weight: 100;
  margin: 0;
}
.certificate-wrapper .first-page .content h3 {
  font-size: 2.5rem;
  margin: 10px 0;
}
.certificate-wrapper .first-page .logo img {
  width: 250px;
}
.certificate-wrapper .sign {
  position: relative;
}
.certificate-wrapper .sign p {
  margin-top: 100px;
  font-size: 14px;
}
.certificate-wrapper .sign img {
  width: 250px;
  position: absolute;
}
.certificate-wrapper .first-page .first-sign img {
  top: 80px;
}
.certificate-wrapper .first-page .second-sign img {
  top: 50px;
}
.certificate-wrapper .second-page .title-image {
  grid-column: 1 / 2;
  grid-row: 1/4;
  background-image: url("/images/bg2.png");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: start;
  padding-right: 50px;
}
.certificate-wrapper .second-page .result {
  grid-column: 2 / 4;
  grid-row: 1/3;
  padding: 40px;
  justify-content: start;
  font-size: 15px;
}
.certificate-wrapper .second-page .title-image h3 {
  color: white;
  font-size: 2.5rem;
  font-weight: 100;
  margin-bottom: 150px;
}
.certificate-wrapper .second-page .title-image img {
  width: 200px;
  margin-bottom: 100px;
}
.certificate-wrapper .second-page .result img {
  width: 200px;
  margin-bottom: 20px;
}
.certificate-wrapper .second-page .result .candidate-info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.certificate-wrapper .second-page .result .score {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
}
.certificate-wrapper .second-page .result .score .right {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.certificate-wrapper .second-page .result .score p {
  margin: 0;
  padding: 14px 0;
}

.download-btn-container {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

@media print {
  @page {
    margin: 0;
    size: A4 landscape;
  }
  body * {
    visibility: hidden;
  }
  .certificate-wrapper,
  .certificate-wrapper * {
    visibility: visible;
  }
  .certificate-wrapper {
    -webkit-print-color-adjust: exact;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .page {
    height: 8.1in;
    width: 11.7in; /* Adjust width for landscape */
  }
}
