* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  background-color: #efefef;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.main-container,
.page-content-container {
  display: grid;
  grid-template-columns: 200px auto;
}
.side-navbar {
  z-index: 98;
  position: relative;
}
.side-navbar,
.secondary-navbar {
  box-shadow: 2px 0px 5px 5px rgba(0, 0, 0, 0.1);
}
.page-content {
  margin: 2rem;
  max-height: calc(100vh - 86px);
  padding-bottom: 40px;
  overflow: scroll;
}

.page-content h1 {
  margin-bottom: 40px;
}

.form-button-group {
  padding-top: 60px;
}

.confirm-dialog-grid-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}
.title-with-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loader-display {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin:auto; 
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.2);
    background-color: white;
    position: relative;
    z-index: 99;
}

.header-logo{
    display: flex;
}
.header-logo a{
    padding: 10px;
}
.header-logo img{
    height: 55px;
    width:auto;
}
.header h1{
    text-align: center;
}

.header-login{
    display: flex;
    justify-self: end;
    background-color: #ececec;
}
.header-login button{
    margin:auto;
    font-size: 25px;
}
 
.navbar{
    height: calc(100vh - 81px);
    background-color: white;
   
}
.navbar ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
.navbar ul li{
    /* background-color: red; */
    height: 50px;
}
.navbar a {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
}
.navbar a.active,
.navbar a:hover { 
    color: #4389E9;
    background-color: #ECECEC;
    font-weight: bold;
    /* height: 50px; */
}
.navbar a.active{
    position: relative;
}
.navbar a.active:after{
    content: "";
    position:absolute;
    top:0;
    height:100%;
    width: 5px;
    right:-15px;
    border-right:15px solid #ECECEC;
}

.d-tag{
    background-color: #ECECEC;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.m-r{
    margin-right: 10px;
}



.secondary-navbar {
  height: calc(100vh - 81px);
  background-color: #ececec;
}
.secondary-navbar ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.secondary-navbar ul li {
  /* height: 50px; */
  z-index: 999;
}
.secondary-navbar ul li a:hover {
  background-color: lightgray;
}
.secondary-navbar a {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0.9rem 1rem;
  align-items: center;
}
.secondary-navbar a.active,
.secondary-navbar a:hover {
  /* color: white; */
  background-color: lightgray;
}

.home-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.home-container h2{
    font-size: 44px;
    color:#4389E9;
}
.home-container p{
    font-size: 24px;
    max-width: 700px;
    text-align: center;
}
.home-container a {
    color: #40BBF7;
}
.tool-bar-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px
}

.btn {
    margin-right: 10px !important;
}
.candidate-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tool-bar-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.certificate-wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.certificate-wrapper .page {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr 3fr 2fr;
  height: 792px;
  width: 100%;
}
.certificate-wrapper .page > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.certificate-wrapper .first-page {
  background-image: url("/images/certificate_bg1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.certificate-wrapper .first-page .title {
  grid-column: 1 / 3;
  color: white;
  align-items: flex-start;
  padding-left: 70px;
}
.certificate-wrapper .first-page .content {
  grid-column: 1 / 3;
  grid-row: 2/3;
  background-color: white;
  align-items: flex-start;
  padding-left: 70px;
  padding-right: 70px;
}
.certificate-wrapper .first-page .logo {
  grid-column: 3/ 4;
  grid-row: 2/3;
  background-color: white;
}
.certificate-wrapper .first-page .verification-code {
  grid-column: 1 / 2;
  grid-row: 3/4;
  justify-content: start;
  align-items: flex-start;
  padding-left: 70px;
}
.certificate-wrapper .first-sign {
  grid-column: 2 / 3;
  grid-row: 3/4;
}
.certificate-wrapper .second-sign {
  grid-column: 3/ 4;
  grid-row: 3/4;
}
.certificate-wrapper .first-page .title h3 {
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0;
  text-align: left;
}
.certificate-wrapper .first-page .title p {
  font-size: 1.2rem;
  font-weight: 100;
  margin: 0;
}
.certificate-wrapper .first-page .content h3 {
  font-size: 2.5rem;
  margin: 10px 0;
}
.certificate-wrapper .first-page .logo img {
  width: 250px;
}
.certificate-wrapper .sign {
  position: relative;
}
.certificate-wrapper .sign p {
  margin-top: 100px;
  font-size: 14px;
}
.certificate-wrapper .sign img {
  width: 250px;
  position: absolute;
}
.certificate-wrapper .first-page .first-sign img {
  top: 80px;
}
.certificate-wrapper .first-page .second-sign img {
  top: 50px;
}
.certificate-wrapper .second-page .title-image {
  grid-column: 1 / 2;
  grid-row: 1/4;
  background-image: url("/images/bg2.png");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: start;
  padding-right: 50px;
}
.certificate-wrapper .second-page .result {
  grid-column: 2 / 4;
  grid-row: 1/3;
  padding: 40px;
  justify-content: start;
  font-size: 15px;
}
.certificate-wrapper .second-page .title-image h3 {
  color: white;
  font-size: 2.5rem;
  font-weight: 100;
  margin-bottom: 150px;
}
.certificate-wrapper .second-page .title-image img {
  width: 200px;
  margin-bottom: 100px;
}
.certificate-wrapper .second-page .result img {
  width: 200px;
  margin-bottom: 20px;
}
.certificate-wrapper .second-page .result .candidate-info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.certificate-wrapper .second-page .result .score {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
}
.certificate-wrapper .second-page .result .score .right {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.certificate-wrapper .second-page .result .score p {
  margin: 0;
  padding: 14px 0;
}

.download-btn-container {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

@media print {
  @page {
    margin: 0;
    size: A4 landscape;
  }
  body * {
    visibility: hidden;
  }
  .certificate-wrapper,
  .certificate-wrapper * {
    visibility: visible;
  }
  .certificate-wrapper {
    -webkit-print-color-adjust: exact;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .page {
    height: 8.1in;
    width: 11.7in; /* Adjust width for landscape */
  }
}

.grading-speaking-wrapper,
.grading-writing-wrapper,
.grading-marks-wrapper {
  background-color: white;
  border-radius: 15px;
  padding: 20px 40px;
  margin: 20px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.grading-speaking-wrapper {
  display: flex;
  align-items: center;
}
.grading-speaking-wrapper .details {
  margin: 0 50px;
  font-size: 22px;
  font-weight: bold;
  width: 70%;
}
.grading-writing-wrapper .details {
  font-size: 22px;
  font-weight: bold;
}
.grading-writing-wrapper .answer {
  background-color: #ececec;
  padding: 20px;
  border-radius: 15px;
  font-size: 18px;
}
.answer-text {
  width: 100%;
  overflow: auto;
}
.final-grade {
  padding-top: 24px;
  padding-left: 32px;
  font-weight: bold;
}
.final-grade p {
  margin-top: 8px;
}
.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.modal-wrapper img {
  width: 100%;
}

.download-name-wrapper {
  border: 3px solid #6878f0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: white;
}
.download-name-wrapper:hover {
  cursor: pointer;
}
.download-name-wrapper.active,
.download-format-wrapper.active {
  background-image: linear-gradient(90deg, #8f32e9, #40bbf7);
  color: white;
  position: relative;
}
.download-format-wrapper {
  display: flex;
  align-items: center;
  border: 3px solid #6878f0;
  border-radius: 10px;
  padding: 3px;
  text-align: center;
  justify-content: center;
  background-color: white;
}
.download-format-button {
  margin-left: 40px !important;
  margin-top: 60px !important;
  background-image: linear-gradient(90deg, #7969e6, #40bbf7);
}


.fa-i {
    font-size: larger;
    margin-right: 10px;
}
.login-page{
    width: 100vw;
    height:100vh;
    min-width: 600px;
    min-height: 600px;
    background-color: white;
    position: fixed;
    z-index: 999;
    top:0;
    display: flex;
}
.login-page-left{
    width:50%;
    background-color: #3A53EA;
    color:white;
    display: flex;
    -webkit-transform: skew(-10deg);
            transform: skew(-10deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
}
.login-page-left h1{
    font-weight: 400;
    font-size: 50px;
    margin:auto;
    border-left: 3px solid white;
    padding:2rem;
}
.login-page-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-around;
    
}
.login-form{
    width: 500px;
}
.login-form button{
    width: 100%;
    height: 56px;
}
.login-page-right-links{
    text-align: center;
    color:#40BBF7;
    text-decoration: underline;
    line-height: 1.6rem;
}

.licenses-card {
  background-color: white;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 250px;
  border-radius: 10px;
  border: 2px solid #6878f0;
}
.licenses-card p {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 10px;
  color: #4389e9;
  font-weight: bold;
  font-size: 18px;
}



.admin-name {
    margin-top: -6px;
    color: #bcbcbc;
    font-size: 16px;
    padding-left: 2px;
}
.admin-name::first-letter {
    text-transform: uppercase;
}

.lable {
    color: #bcbcbc;
}

.value {
    margin-top: -17px;
    font-size: 16px;
    color: #313131;
}

.licenses {
    margin-bottom: -6px;
    border-bottom: 1px solid;
    padding-bottom: 5px;
}

.upper-case::first-letter {
    text-transform: uppercase;
}

