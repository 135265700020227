
.admin-name {
    margin-top: -6px;
    color: #bcbcbc;
    font-size: 16px;
    padding-left: 2px;
}
.admin-name::first-letter {
    text-transform: uppercase;
}

.lable {
    color: #bcbcbc;
}

.value {
    margin-top: -17px;
    font-size: 16px;
    color: #313131;
}

.licenses {
    margin-bottom: -6px;
    border-bottom: 1px solid;
    padding-bottom: 5px;
}

.upper-case::first-letter {
    text-transform: uppercase;
}
