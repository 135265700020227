.candidate-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tool-bar-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}