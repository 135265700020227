.grading-speaking-wrapper,
.grading-writing-wrapper,
.grading-marks-wrapper {
  background-color: white;
  border-radius: 15px;
  padding: 20px 40px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.grading-speaking-wrapper {
  display: flex;
  align-items: center;
}
.grading-speaking-wrapper .details {
  margin: 0 50px;
  font-size: 22px;
  font-weight: bold;
  width: 70%;
}
.grading-writing-wrapper .details {
  font-size: 22px;
  font-weight: bold;
}
.grading-writing-wrapper .answer {
  background-color: #ececec;
  padding: 20px;
  border-radius: 15px;
  font-size: 18px;
}
.answer-text {
  width: 100%;
  overflow: auto;
}
.final-grade {
  padding-top: 24px;
  padding-left: 32px;
  font-weight: bold;
}
.final-grade p {
  margin-top: 8px;
}
.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-wrapper img {
  width: 100%;
}
