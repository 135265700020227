* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  background-color: #efefef;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.main-container,
.page-content-container {
  display: grid;
  grid-template-columns: 200px auto;
}
.side-navbar {
  z-index: 98;
  position: relative;
}
.side-navbar,
.secondary-navbar {
  -webkit-box-shadow: 2px 0px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0px 5px 5px rgba(0, 0, 0, 0.1);
}
.page-content {
  margin: 2rem;
  max-height: calc(100vh - 86px);
  padding-bottom: 40px;
  overflow: scroll;
}

.page-content h1 {
  margin-bottom: 40px;
}

.form-button-group {
  padding-top: 60px;
}

.confirm-dialog-grid-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
.title-with-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loader-display {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
