.secondary-navbar {
  height: calc(100vh - 81px);
  background-color: #ececec;
}
.secondary-navbar ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.secondary-navbar ul li {
  /* height: 50px; */
  z-index: 999;
}
.secondary-navbar ul li a:hover {
  background-color: lightgray;
}
.secondary-navbar a {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0.9rem 1rem;
  align-items: center;
}
.secondary-navbar a.active,
.secondary-navbar a:hover {
  /* color: white; */
  background-color: lightgray;
}
